/* Page-specific styles */
.content-container {
    display: flex;
    justify-content: center;
    background-color: #f0f4f8;
    /* Light background to differentiate sections */
    padding: 20px;
    border-radius: 10px;
    max-width: 900px;
    margin: 0 auto;
}

.content {
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
    /* Clean white background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    max-width: 900px;
    margin: 0 auto;
}

h1 {
    font-size: 2.2em;
    color: #007bff;
    /* Blue for page title */
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.8em;
    color: #17a2b8;
    /* Cyan for section headings */
    text-align: center;
    margin-bottom: 15px;
}

h3,
h4 {
    font-size: 1.4em;
    color: #343a40;
    /* Dark text for subheadings */
    margin-bottom: 10px;
}

p,
ul,
ol {
    font-size: 1.1em;
    line-height: 1.6;
    color: #495057;
    text-align: left;
}

ul,
ol {
    margin-left: 20px;
    list-style-position: outside;
}

.section-divider {
    margin: 40px 0;
    border: 0;
    height: 1px;
    background: rgba(23, 162, 184, 0.2);
    /* Subtle cyan divider */
}

.code-block pre {
    background-color: #1e5994;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    line-height: 1.5;
    box-shadow: 0 0 5px rgba(63, 6, 6, 0.1);
}

.code-block code {
    color: #4d961c;
}

.copy-button {
    background-color: #0b0c0c;
    color: white;
    border: none;
    padding: 5px 10px;
    /* Reduce padding for a smaller button */
    border-radius: 2px;
    font-size: 0.85em;
    cursor: pointer;
    margin-top: 10px;
}

.copy-button:hover {
    background-color: #0056b3;
}

pre {
    background-color: #dfecf0;
    padding: 15px;
    border-radius: 5px;
    font-size: 1em;
    line-height: 1.5;
    color: #343a40;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* CodeBlock-specific styling */
.code-block {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 15px;
    border-radius: 8px;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 20px;
    overflow-x: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.4em;
    }

    p,
    ul,
    ol {
        font-size: 1em;
    }

    h3,
    h4 {
        font-size: 1.2em;
    }
}