.sidebar-container {
    position: relative;
}

.sidebar {
    width: 220px;
    background-color: rgb(18, 18, 61);
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    transition: transform 0.3s ease;
}

.sidebar-content {
    padding: 20px;
}

.sidebar-header {
    text-align: center;
    margin-bottom: 30px;
}

.sidebar-logo {
    max-width: 100%;
    height: auto;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 15px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #ecf0f1;
    font-size: 1.1em;
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar ul li a:hover {
    background-color: #007bff;
    color: white;
}

.sidebar ul li a.active {
    background-color: #3498db;
    color: white;
}

.content {
    margin-left: 240px;
    padding: 20px;
    background-color: #ffffff;
    width: calc(100% - 240px);
}

.page-content {
    max-width: 800px;
    margin: 0 auto;
}

.next-button {
    text-align: left;
    margin-top: 20px;
}

.next-button a {
    text-decoration: none;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.sidebar-toggle {
    display: none;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1.1em;
    margin: 10px;
    border-radius: 5px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001;
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
        width: 100%;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-toggle {
        display: block;
    }

    .content {
        margin-left: 0;
        width: 100%;
    }
}