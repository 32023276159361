.lockable-content-container {
  position: relative;
}

.locked-overlay {
  position: relative;
  text-align: center;
  color: white;
}

.blurred-content {
  filter: blur(8px);
  pointer-events: none;
  opacity: 0.7;
}

.unlock-button-container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -20%);
  text-align: center;
}

.unlock-button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  animation: lock-animation 1s ease-in-out infinite;
}

@keyframes lock-animation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.pay-to-unlock {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
