.mla-paper-example {
    font-family: 'Times New Roman', Times, serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
}

.header h1 {
    text-align: center;
    font-weight: bold;
    margin-top: 60px;
}

.header p {
    text-align: center;
    margin: 5px 0;
}

.cover-page-explanation,
.explanation {
    font-size: 0.9em;
    color: #555555;
    margin-top: 10px;
    border-left: 3px solid #007bff;
    padding-left: 10px;
}

.body-text {
    margin-top: 30px;
}

.body-text h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 30px;
}

.highlight {
    background-color: #fdfda0;
    padding: 2px 4px;
}

.works-cited h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.works-cited ul {
    list-style-type: none;
    padding: 0;
}

.works-cited li {
    margin-bottom: 10px;
}

.explanation {
    font-size: 0.9em;
    color: #f80000;
    margin-top: 5px;
}



.download-button-container {
    text-align: center;
    margin-top: 40px;
}

.download-button img {
    vertical-align: middle;
    cursor: pointer;
}

.download-button svg {
    vertical-align: middle;
    cursor: pointer;
}

.nav-icon {
    background: rgba(0, 123, 255, 0.7); /* Using the blue from your header */
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease;
    pointer-events: auto;
}

.nav-icon:hover {
    background: rgba(0, 123, 255, 0.9);
}

.nav-icon.left {
    margin-left: 20px;
}

.nav-icon.right {
    margin-right: 20px;
}

@media (max-width: 768px) {
    /* Adjustments for navigation on smaller screens */
    .nav-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
    
    .nav-icon.left {
        margin-left: 10px;
    }
    
    .nav-icon.right {
        margin-right: 10px;
    }
}