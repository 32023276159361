/* Page-specific styles */
.content-container {
    display: flex;
    justify-content: center;
    background-color: #f4f6f9;
    /* Same background as your article guide */
    padding: 20px;
    border-radius: 10px;
    max-width: 900px;
    margin: 0 auto;
}

.content {
    font-family: 'Georgia', serif;
    background-color: #ffffff;
    /* Keeping a clean white background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 0 auto;
}

h1 {
    font-size: 2.5em;
    color: #007bff;
    /* Same blue as in header */
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.8em;
    color: #28a745;
    /* Green for subheadings */
    text-align: center;
    margin-bottom: 15px;
}

h3,
h4,
h5 {
    font-size: 1.4em;
    color: #343a40;
    margin-bottom: 10px;
}

p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #495057;
    text-align: left;
}

ul,
ol {
    font-size: 1.1em;
    color: #495057;
    margin-left: 20px;
    list-style-position: outside;
}

.section-divider {
    margin: 40px 0;
    border: 0;
    height: 1px;
    background: rgba(0, 123, 255, 0.2);
    /* Subtle blue divider */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.4em;
    }

    p,
    ul,
    ol {
        font-size: 1em;
    }

    h3,
    h4,
    h5 {
        font-size: 1.2em;
    }
}

/* Reuse navigation styles */
.navigation-icons {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    z-index: 1000;
}

.nav-icon {
    background: rgba(0, 123, 255, 0.7);
    /* Using the blue from header */
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease;
    pointer-events: auto;
}

.nav-icon:hover {
    background: rgba(0, 123, 255, 0.9);
}

.nav-icon.left {
    margin-left: 20px;
}

.nav-icon.right {
    margin-right: 20px;
}

.code-block pre {
    background-color: #1e5994;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    line-height: 1.5;
    box-shadow: 0 0 5px rgba(63, 6, 6, 0.1);
}

.code-block code {
    color: #4d961c;
}

.copy-button {
    background-color: #0b0c0c;
    color: white;
    border: none;
    padding: 5px 10px;
    /* Reduce padding for a smaller button */
    border-radius: 2px;
    font-size: 0.85em;
    cursor: pointer;
    margin-top: 10px;
}

.copy-button:hover {
    background-color: #0056b3;
}

pre {
    background-color: #dfecf0;
    padding: 15px;
    border-radius: 5px;
    font-size: 1em;
    line-height: 1.5;
    color: #343a40;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .nav-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .nav-icon.left {
        margin-left: 10px;
    }

    .nav-icon.right {
        margin-right: 10px;
    }
}