.quiz-container {
    font-family: 'Georgia', serif;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
}
.quiz-header {
    text-align: center;
    margin-bottom: 20px;
}
.quiz-header h1 {
    font-size: 2.5em;
    color: #007bff;
    margin-bottom: 10px;
}
.question-block {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.question-block h2 {
    font-size: 1.8em;
    color: #28a745;
    margin-bottom: 15px;
}
.question-block p,
.question-block ul {
    font-size: 1.1em;
    line-height: 1.6;
    color: #495057;
}
.question-block button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 5px;
    cursor: pointer;
}
.question-block button:hover {
    background-color: #0056b3;
}
.option {
    display: block;
    margin: 10px 0;
    color: #495057;
}
.option input {
    margin-right: 10px;
}
.feedback {
    margin-top: 20px;
    font-size: 1.2em;
    color: green;
}
.score {
    font-size: 1.5em;
    font-weight: bold;
    color: #28a745;
}
.nav-icon {
    background: rgba(0, 123, 255, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease;
    pointer-events: auto;
}
.nav-icon:hover {
    background: rgba(0, 123, 255, 0.9);
}
.nav-icon.left {
    margin-left: 20px;
}
.nav-icon.right {
    margin-right: 20px;
}

/* Essay part styles */
.essay-question {
    margin-top: 20px;
    padding: 15px;
    background-color: #e9ecef;
    border-radius: 5px;
}

.essay-question h3 {
    color: #007bff;
    margin-bottom: 10px;
}

.essay-question p {
    margin-bottom: 10px;
}

.essay-question p strong {
    color: #28a745;
}

#essay-topic {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1em;
    color:#495057;
}

#essay-content {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1em;
    resize: vertical;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .quiz-header h1 {
        font-size: 1.8em;
    }
    .question-block h2 {
        font-size: 1.4em;
    }
    .question-block p,
    .question-block ul {
        font-size: 1em;
    }
    .nav-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
    
    .nav-icon.left {
        margin-left: 10px;
    }
    
    .nav-icon.right {
        margin-right: 10px;
    }

    .essay-question h3 {
        font-size: 1.2em;
    }

    .essay-question p {
        font-size: 0.9em;
    }
}