/* Base styles */
.sidebar {
    width: 240px;
    background-color: #172635;
    color: white;
    padding: 20px;
    position: fixed;
    height: 100%;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  /* Sidebar closed by default on small screens */
  .sidebar.closed {
    transform: translateX(-100%);
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-toggle-button {
    display: none;
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #172635;
    color: white;
    padding: 10px 15px;
    font-size: 1.5em;
    cursor: pointer;
    z-index: 1100;
  }
  
  .sidebar-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .sidebar-logo {
    max-width: 100%;
    height: auto;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 15px;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #ecf0f1;
    font-size: 1.1em;
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .sidebar ul li a:hover {
    background-color: #007bff;
    color: white;
  }
  
  .sidebar ul li a.active {
    background-color: #3498db;
    color: white;
  }
  
  /* Adjust content to make room for the sidebar */
  .content {
    margin-left: 240px;
    padding: 20px;
    background-color: #ffffff;
    width: calc(100% - 240px);
  }
  
  .next-button {
    text-align: left;
    margin-top: 20px;
  }
  
  .next-button a {
    text-decoration: none;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .next-button a:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%);
      position: fixed;
      width: 240px;
      height: 100%;
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .sidebar-toggle-button {
      display: block;
    }
  
    .content {
      margin-left: 0;
      width: 100%;
    }
  
    .next-button a {
      width: 100%;
      text-align: center;
      padding: 12px 0;
    }
  }
  