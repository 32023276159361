.transcription-intro {
    font-family: 'Arial', sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
}

.header h1 {
    font-size: 2.5em;
    color: #007bff;
    margin-bottom: 10px;
}

.header .subtitle {
    font-size: 1.2em;
    color: #6c757d;
}

.content {
    max-width: 800px;
    margin: 0 auto;
}

.section-block {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.section-block h2 {
    font-size: 1.8em;
    color: #28a745;
    margin-bottom: 15px;
    text-align: center;
}

.section-block p,
.section-block ul {
    font-size: 1.1em;
    line-height: 1.6;
    color: #495057;
}

.section-block ul {
    padding-left: 20px;
}

.section-block ul li {
    margin-bottom: 10px;
}

.nav-icon {
    background: rgba(0, 123, 255, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.nav-icon:hover {
    background: rgba(0, 123, 255, 0.9);
}

.nav-icon.right {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .nav-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .nav-icon.right {
        margin-right: 10px;
    }
}