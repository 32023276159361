/* src/pages/BasicIO.css */
.article-guide-container {
    font-family: 'Georgia', serif;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
    /* Added for positioning context */
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 2.5em;
    color: #007bff;
    margin-bottom: 10px;
}

.content {
    margin: 0 auto;
    text-align: left;
}

.section-block {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.section-block h2 {
    font-size: 1.8em;
    color: #28a745;
    margin-bottom: 15px;
    text-align: center;
}

.section-block p,
.section-block ul,
.section-block ol {
    font-size: 1.1em;
    line-height: 1.6;
    color: #495057;
}

.section-block h3,
.section-block h4,
.section-block h5 {
    font-size: 1.4em;
    color: #343a40;
    margin-bottom: 10px;
}

/* CodeBlock specific styling */
.CodeBlock pre {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    line-height: 1.5;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.CodeBlock code {
    color: #d63384;
}

/* New navigation styles */
.navigation-icons {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
    /* Adjust based on your layout */
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    z-index: 1000;
}

.nav-icon {
    background: rgba(0, 123, 255, 0.7);
    /* Using the blue from your header */
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease;
    pointer-events: auto;
}

.nav-icon:hover {
    background: rgba(0, 123, 255, 0.9);
}

.nav-icon.left {
    margin-left: 20px;
}

.nav-icon.right {
    margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header h1 {
        font-size: 1.8em;
    }

    .section-block h2 {
        font-size: 1.4em;
    }

    .section-block p,
    .section-block ul,
    .section-block ol {
        font-size: 1em;
    }

    .section-block h3,
    .section-block h4,
    .section-block h5 {
        font-size: 1.2em;
    }

    .code-block pre {
        background-color: #1e5994;
        padding: 15px;
        border-radius: 5px;
        font-size: 0.9em;
        line-height: 1.5;
        box-shadow: 0 0 5px rgba(63, 6, 6, 0.1);
    }

    .code-block code {
        color: #4d961c;
    }

    .copy-button {
        background-color: #0b0c0c;
        color: white;
        border: none;
        padding: 5px 10px;
        /* Reduce padding for a smaller button */
        border-radius: 2px;
        font-size: 0.85em;
        cursor: pointer;
        margin-top: 10px;
    }

    .copy-button:hover {
        background-color: #0056b3;
    }

    pre {
        background-color: #dfecf0;
        padding: 15px;
        border-radius: 5px;
        font-size: 1em;
        line-height: 1.5;
        color: #343a40;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    /* Adjustments for navigation on smaller screens */
    .nav-icon {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .nav-icon.left {
        margin-left: 10px;
    }

    .nav-icon.right {
        margin-right: 10px;
    }
}